import { Grid, Typography } from '@mui/material'
import React from 'react'
import theme from '../../theme'

const Breadcrumb = ({ title, content }) => {
    return (
        <Grid
            sx={{
                my: 0.1,
                p: 5,
                color: 'white',
                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            }}
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant='h3' textAlign='center'>{title}</Typography>
                <Typography textAlign='center'>{content}</Typography>
            </Grid>
        </Grid>
    )
}

export default Breadcrumb
