import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { useScrollTrigger, useTheme } from "@mui/material";
import { menuList } from "../menuList";
import WhatsApp from "@mui/icons-material/WhatsApp";

const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        top: 0,
        backgroundColor: trigger ? theme.palette.background.paper : "black",
      }}
      elevation={trigger ? 1 : 0}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
            >
              <MenuIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
          <Typography
            component="a"
            sx={{ mr: 0.5, flexGrow: 1, textAlign: "center" }}
            href={"/"}
          >
            <img src="/images/logo-secondary.png" alt="logo" width={100} />
          </Typography>
          <Box sx={{ ml: 2, flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ m: 3 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    color: "white",
                    fontSize: 15,
                    m: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}
          </Box>
          <Button
            component="a"
            href="tel:+243811765187"
            disableElevation
            sx={{
              display: { xs: "none", md: "flex" },
              borderRadius: 10,
            }}
            startIcon={<WhatsApp />}
            variant="contained"
          >
            Contactez-nous
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
