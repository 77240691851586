import { Box, Button, Grid, Container } from "@mui/material";
import React from "react";

const TopLink = ({ activePath }) => {
  return (
    <Box sx={{ backgroundColor: "#111", mt: -3 }}>
      <Container>
        <Grid container sx={{ m: 3 }} spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Button
                sx={{ fontSize: 17, color: "white" }}
                color="inherit"
                component="a"
                href="/"
              >
                Accueil
              </Button>
              <Button sx={{ fontSize: 17 }}>/{activePath}</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TopLink;
