import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const Projects = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  const projects = [
    {
      name: "Motuka",
      description: `MOTUKA est une application mobile, qui révolutionne le processus d'achat et de vente de voitures d'occasion tout en offrant une expérience transparente aux personnes cherchant à trouver la voiture de leurs rêves ou à gagner de l'argent supplémentaire en vendant leur véhicule.
`,
      image: "/images/motuka.png",
      link: "https://motukapp.com",
    },
    {
      name: "Caravex",
      description: `CARAVEX TMS ( Transport Management System ) , est un plateforme en tant que service destiné aux acteurs de la logistique de transport. Il offre des fonctionnalités avancées de pilotage de leur activité et de suivi de rentabilité de leur entreprise.
`,
      image: "/images/caravex.png",
      link: "https://caravex.net",
    },
    {
      name: "Kuistoo",
      description: `Kuistoo est l'application mobile révolutionnaire qui vous permet de commander vos repas préférés auprès de vos restaurants locaux préférés en quelques clics. Que vous soyez à la maison, au bureau ou en déplacement, Kuistoo vous facilite la vie en vous offrant un accès instantané à une variété de cuisines délicieuses.`,
      image: "/images/kuistoo.png",
      link: "https://kuistoo.com",
    },
    {
      name: "Market influence",
      description: `Que vous souhaitiez créer des contenus UGC engageants, mener des campagnes de marketing d'influence efficaces ou produire des podcasts de qualité, Market Influence vous offre tout ce dont vous avez besoin pour atteindre vos objectifs marketing.`,
      image: "/images/market1fluence.png",
      link: "https://market1fluence.hashen.io",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#111", py: 5 }}>
      <Container>
        <Box>
          <Typography color={"primary"} variant="h3" textAlign={"center"}>
            Nos projets
          </Typography>
          <Typography color={"white"} sx={{ my: 2 }} textAlign={"center"}>
            Au cours des années, nous avons développé des projets nnovants dans
            divers secteurs
          </Typography>
        </Box>
        <Slider {...settings}>
          {projects.map((projet) => (
            <Box>
              <Grid direction={"row"} sx={{ my: 3 }} container spacing={2}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ p: 3 }}
                  item
                  xs={12}
                  md={6}
                >
                  <img
                    style={{ borderRadius: 20 }}
                    src={projet.image}
                    width={"60%"}
                    alt=""
                  />
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item
                  xs={12}
                  md={6}
                >
                  <Box sx={{ p: 3 }}>
                    <Typography sx={{ my: 2 }} color={"white"} variant="h4">
                      {projet.name}
                    </Typography>
                    <Typography color={"white"}>
                      {" "}
                      {projet.description}{" "}
                    </Typography>
                    <Typography sx={{ my: 2 }}>
                      <Button
                        component="a"
                        href={projet.link}
                        sx={{ borderRadius: 10 }}
                        variant="contained"
                      >
                        En savoir plus
                      </Button>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Projects;
