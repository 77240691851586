export const menuList = [
  {
    name: "Image de marque",
    href: "/services/image-de-marque",
  },
  {
    name: "Marketing digital",
    href: "/services/marketing-digital",
  },
  {
    name: "Site/application web",
    href: "/services/site-web",
  },
  {
    name: "Application mobile",
    href: "/services/application-mobile",
  },
];
