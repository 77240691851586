const initState = {
    loading: false
}

export const mailReducer = (state = initState, action) => {
    switch (action.type) {
        case "CREATE_CONTACT_LOADING":
            return {
                ...state,
                loading: action.payload
            };
        case "CREATE_CONTACT_SUCCESS":
            return {
                ...state,
                loading: false
            };
        case "CREATE_CONTACT_ERROR":
            return {
                ...state,
                loading: false 
            };

        default:
            return state;
    }
}