import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import Banner from "../../components/includes/Banner";
import Newsletter from "../../components/includes/Newsletter";
import TopLink from "../../components/includes/TopLink";
import { Helmet } from "react-helmet";

const About = () => {
  const features = [
    {
      title: "Nous sommes créatifs",
      content:
        " Du logo de votre entreprise en passant par le développement de votre site vitrine ou e-commerce, nous réalisons de A à Z et sur mesure l'ensemble de votre projet.",
    },
    {
      title: "Nous sommes conseillers",
      content:
        "Nous effectuons des audits concurrentiels, une étude de faisabilité et vous proposons ensuite des solutions réalistes et adaptées à votre projet.",
    },
    {
      title: "Nous sommes passionés",
      content:
        " Si vous aimez travaillez avec des pros passionnés par leur domaine d'activité, alors vous allez nous adorer.",
    },
  ];
  return (
    <main>
      <Helmet>
        <title>A propos</title>
        <meta
          name="description"
          content="A propos de Hashen, nos valeurs et notre mission."
        />
      </Helmet>
      <TopLink activePath={" A propos"} />
      <Banner
        title={"Découvrez-nous"}
        content={
          <span>
            Créé en 2018, Hashen est un studio de création numérique qui offre
            divers services pour vous aider à propulser votre entreprise. <br />{" "}
            Nous accompagnons nos clients, de la start-up aux grands groupes
            dans le developpement de leur stratégie digitale. <br /> De l'idée à
            la réalisation, notre métier est d'apporter l'équilibre entre
            créativité et innovation dans la transformation numérique. <br />{" "}
            Notre Expérience et notre analyse du web nous permettent de
            développer et de proposer à nos clients des solutions qui optimisent
            la visibilié de leur Entreprise sur internet et les aident à faire
            gripper leur chiffre d'affaire en ligne.
          </span>
        }
        image={<img src="/images/information.png" alt="A propos" width="50%" />}
      />
      <Grid container sx={{ p: 3, backgroundColor: "#eee" }} spacing={2}>
        {features.map((item) => (
          <Fragment>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 3 }}>
                <Typography color="primary" variant="h5">
                  {item.title}
                </Typography>
                <Typography>{item.content}</Typography>
              </Paper>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Newsletter />
    </main>
  );
};

export default About;
