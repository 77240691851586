import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";

const HomeServices = () => {
  const services = [
    {
      name: "Logo & Charte",
      href: "/",
    },
    {
      name: "Motion & Graphic design",
      href: "/",
    },
    {
      name: "Communication visuelle",
      href: "/",
    },
    {
      name: "Création de contenus & UGC",
      href: "/",
    },
    {
      name: "Stratégie marketing digital",
      href: "/",
    },
    {
      name: "Référencement SEO",
      href: "/",
    },
    {
      name: "Campagnes digitales",
      href: "/",
    },
    {
      name: "Marketing d'influence",
      href: "/",
    },
    {
      name: "E-mail & SMS marketing",
      href: "/",
    },
    {
      name: "Analyse marketing et reporting",
      href: "/",
    },
    {
      name: "Site web",
      href: "/",
    },
    {
      name: "Application web",
      href: "/",
    },
    {
      name: "Application mobile",
      href: "/",
    },
    {
      name: "Boutique en ligne",
      href: "/",
    },
    {
      name: "Maintenance web/mobile",
      href: "/",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#111", mt: -1, py: 5 }}>
      <Container>
        <Box>
          <Typography color={"primary"} textAlign={"center"}>
            Tous les services
          </Typography>
          <Typography
            sx={{ my: 2 }}
            color={"white"}
            variant="h4"
            textAlign={"center"}
          >
            Votre partenaire de confiance pour une transformation digitale
            réussie!
          </Typography>
          <Typography
            sx={{ my: 5, display: { xs: "none", md: "block" } }}
            textAlign={"center"}
          >
            <img src="/images/laptop.png" width={"60%"} alt="laptop" />
          </Typography>
          <Box sx={{ my: 3 }}>
            {services.map((service) => (
              <Button
                sx={{
                  color: "white",
                  borderColor: "white",
                  mr: 1,
                  mt: 1,
                  borderRadius: 10,
                }}
                variant="outlined"
              >
                {service.name}{" "}
              </Button>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeServices;
