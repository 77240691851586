import {
  Box,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const Services = ({ services }) => {
  return (
    <Box sx={{ backgroundColor: "#111", mt: -1, py: 5 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color={"primary"} textAlign={"center"}>
              Tous les services
            </Typography>
            <Typography
              sx={{ my: 2 }}
              color={"white"}
              variant="h4"
              textAlign={"center"}
            >
              Votre partenaire de confiance pour une transformation digitale
              réussie!
            </Typography>
          </Grid>

          <Grid item spacing={1} container xs={12} md={6}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  fieldset: { borderColor: "white" },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                }}
                name="name"
                label="Votre Nom"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="business"
                label="Nom l'entreprise"
                sx={{
                  fieldset: { borderColor: "white" },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  fieldset: { borderColor: "white" },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                }}
                fullWidth
                name="email"
                label="Adresse e-mail"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{
                  fieldset: { borderColor: "white" },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                }}
                select
                fullWidth
                name="service"
                label="Selectionner un service"
              >
                {services.map((service) => (
                  <MenuItem value={service.name}>{service.name} </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                fullWidth
                name="message"
                label="Décrivez votre besoin..."
                sx={{
                  fieldset: { borderColor: "white" },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ borderRadius: 10, my: 2 }}
              >
                {" "}
                Envoyer
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box sx={{ my: 3 }}>
                {services.map((service) => (
                  <Button
                    sx={{
                      color: "white",
                      borderColor: "white",
                      mr: 1,
                      mt: 1,
                      borderRadius: 10,
                    }}
                    variant="outlined"
                  >
                    {service.name}{" "}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
