import { Box, Button, Container, Grid, Typography, Paper } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const HomeFeatures = () => {
  const features = [
    {
      title: "Innovation",
      content:
        " Nous sommes constamment à l'affût des nouvelles technologies et tendances digitales pour vous offrir les meilleures solutions possibles.",
    },
    {
      title: "Créativité",
      content:
        "Nous pensons que le digital doit être avant tout une expérience créative et engageante pour vos utilisateurs.",
    },
    {
      title: "Performance",
      content:
        "Nous mesurons l'impact de nos actions et nous nous engageons à vous fournir des résultats concrets.",
    },
    {
      title: "Partenariat",
      content:
        "Nous établissons des relations durables avec nos clients et nous sommes à vos côtés pour vous accompagner dans votre réussite digitale.",
    },
  ];

  const services = [
    {
      tag: "Image de marque",
      name: "Créez votre marque",
      description: `Hashen est une agence de branding créative et passionnée
                      qui aide les entreprises comme la vôtre à créer une
                      identité de marque inoubliable. Nous travaillons en
                      étroite collaboration avec vous pour comprendre votre
                      vision, vos valeurs et votre public cible. Ensuite, nous
                      traduisons cette vision en une image de marque cohérente
                      et percutante qui propulsera votre entreprise vers de
                      nouveaux sommets.`,
      href: "/services/image-de-marque",
      image: "/images/branding.png",
    },
    {
      tag: "Marketing digital",
      name: "Faites du bruit sur le web!",
      description: `
                Vous cherchez à captiver votre public cible, stimuler l'engagement et générer des résultats concrets ? Notre service de marketing digital complet est la solution idéale pour votre entreprise.
                Nous sommes une équipe d'experts passionnés qui travaillent en étroite collaboration avec vous pour élaborer une stratégie digitale personnalisée, adaptée à vos objectifs et à votre budget.
      `,
      href: "/services/marketing-digital",
      image: "/images/marketing.png",
    },
    {
      tag: "Site/Application web",
      name: "Avec nous, votre entreprise décolle sur le web.",
      description: `
                    Hashen est une agence de développement web expérimentée et passionnée qui aide les entreprises comme la vôtre à créer des sites web et des applications mobiles qui répondent à vos besoins et dépassent vos attentes. Nous travaillons en étroite collaboration avec vous pour comprendre votre vision, votre public cible et vos objectifs commerciaux. Ensuite, nous traduisons cette vision en une réalité numérique captivante qui vous permettra d'atteindre de nouveaux sommets.`,
      href: "/services/site-web",
      image: "/images/web-mobile.png",
    },
    {
      tag: "Application mobile",
      name: "Ensemble, créons l'application mobile de vos rêves.",
      description: `Que vous souhaitiez fidéliser vos clients, améliorer l'efficacité de vos opérations ou simplement créer une expérience utilisateur unique, nous avons les compétences et l'expérience nécessaires pour concrétiser votre vision.`,
      href: "/services/application-mobile",
      image: "/images/mobileapp.png",
    },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <div>
      <Box sx={{ backgroundColor: "black", mt: -1 }}>
        <Container sx={{ py: 5 }}>
          <Grid container spacing={2}>
            {features.map((item) => (
              <Grid item xs={12} md={3}>
                <Paper sx={{ p: 3, backgroundColor: "#111" }}>
                  <Typography color="primary" variant="h5">
                    {item.title}
                  </Typography>
                  <Typography color={"white"}>{item.content}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Slider {...settings}>
            {services.map((service) => (
              <Box>
                <Grid
                  direction={"row-reverse"}
                  sx={{ mt: 3 }}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <Typography textAlign={"center"}>
                      <img
                        style={{ borderRadius: 20 }}
                        src={service.image}
                        width={"100%"}
                        alt=""
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item
                    xs={12}
                    md={6}
                  >
                    <Box sx={{ p: 3 }}>
                      <Typography color={"primary"}>{service.tag}</Typography>
                      <Typography sx={{ my: 2 }} color={"white"} variant="h4">
                        {service.name}
                      </Typography>
                      <Typography color={"white"}>
                        {" "}
                        {service.description}{" "}
                      </Typography>
                      <Typography sx={{ my: 2 }} color={"primary"}>
                        Prêt à faire passer votre entreprise au niveau supérieur
                        ?
                      </Typography>
                      <Typography>
                        <Button
                          component="a"
                          href="/creez-votre-marque"
                          sx={{ borderRadius: 10, mr: 1 }}
                          variant="outlined"
                        >
                          En savoir plus
                        </Button>
                        <Button
                          component="a"
                          href={service.href}
                          sx={{ borderRadius: 10 }}
                          variant="contained"
                        >
                          Nous contacter
                        </Button>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Slider>
        </Container>
      </Box>
    </div>
  );
};

export default HomeFeatures;
