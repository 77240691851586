import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import Slider from "react-slick";

const Testimonials = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      <Container sx={{ py: 3 }}>
        <Typography color={"primary"}>Témoignages</Typography>
        <Typography sx={{ my: 2 }} color={"white"} variant="h4">
          {" "}
          Nos clients temoignent
        </Typography>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <Box>
              <Box sx={{ m: 1, p: 3, backgroundColor: "#111" }}>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ my: 2 }} color={"primary"}>
                    {testimonial.service}
                  </Typography>
                  <Typography color={"whitesmoke"}>
                    {testimonial.content}{" "}
                  </Typography>
                  <Typography sx={{ my: 2 }} color={"primary"}>
                    {testimonial.author}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Testimonials;
