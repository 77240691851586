import React from "react";
import Hero from "../../components/home/Hero";
import Projects from "../../components/home/Projects";
import HomeFeatures from "../../components/home/Features";
import HomeServices from "../../components/home/Services";
import HomeStats from "../../components/home/Stats";
import CallToAction from "../../components/home/CallToAction";
import Testimonials from "../../components/home/Testimonials";
import HomePartners from "../../components/home/Partners";

const Home = () => {
  const testimonials = [
    {
      author: "Calvin Tepeu, Directeur Général de Tepimmo",
      content:
        "J'ai été très impressionné par le professionnalisme et l'expertise de Hashen dans le développement de mon application mobile. Ils ont su comprendre ma vision et la concrétiser en une application performante et conviviale. Mes utilisateurs adorent l'application et cela a eu un impact positif significatif sur mon entreprise.",
      service: "Application mobile",
    },
    {
      author:
        "Pierre Kabongo, Chef d'entreprise dans le secteur du transport à Lubumbashi",
      content:
        "Hashen a développé une application mobile incroyable pour mon entreprise de transport. L'application est facile à utiliser et a permis à nos clients de réserver des trajets en ligne en toute simplicité. Cela a eu un impact positif significatif sur notre efficacité et notre rentabilité. Je suis très reconnaissant pour leur travail.",
      service: "Application mobile",
    },
    {
      author:
        "Carole Mbuyi, Responsable marketing d'une agence de tourisme à Goma",
      content:
        "Grâce à l'expertise de Hashen en matière de référencement SEO, notre site web est désormais beaucoup mieux positionné sur les moteurs de recherche. Nous avons constaté une augmentation significative du trafic organique et des réservations de voyages depuis que nous avons fait appel à leurs services. Ils sont vraiment des experts en SEO et je les recommande vivement à toutes les entreprises congolaises qui souhaitent améliorer leur visibilité en ligne.",
      service: "Référencement SEO",
    },
    {
      author: "Thony Awewe, Influenceur mode sur Instagram à Kinshasa",
      content:
        "Hashen m'a aidé à développer ma communauté Instagram et à augmenter mon engagement avec mes followers. Ils ont créé du contenu créatif et pertinent qui a attiré de nouveaux abonnés et a généré plus de likes et de commentaires. Je suis très satisfait de leurs services et je les recommande à tous les influenceurs congolais qui cherchent à développer leur audience en ligne.",
      service: "Marketing des réseaux sociaux",
    },
    {
      author: "Sophie Ngalande, Responsable communication d'une ONG à Bukavu",
      content:
        "J'ai été très impressionnée par les campagnes de marketing par email réalisées par Hashen. Ils ont créé des emails personnalisés et attrayants qui ont généré un taux d'ouverture et de clics élevé. Nous avons également pu constater une augmentation des dons grâce à leurs campagnes. Je suis très reconnaissante pour leur travail et je les recommande vivement à toutes les ONG congolaises qui cherchent à collecter des fonds en ligne.",
      service: "E-mail marketing",
    },
  ];
  const services = [
    {
      name: "Logo & Charte",
      href: "/",
    },
    {
      name: "Motion & Graphic design",
      href: "/",
    },
    {
      name: "Communication visuelle",
      href: "/",
    },
    {
      name: "Création de contenus & UGC",
      href: "/",
    },
    {
      name: "Stratégie marketing digital",
      href: "/",
    },
    {
      name: "Référencement SEO",
      href: "/",
    },
    {
      name: "Campagnes digitales",
      href: "/",
    },
    {
      name: "Marketing d'influence",
      href: "/",
    },
    {
      name: "E-mail & SMS marketing",
      href: "/",
    },
    {
      name: "Analyse marketing et reporting",
      href: "/",
    },
    {
      name: "Site web",
      href: "/",
    },
    {
      name: "Application web",
      href: "/",
    },
    {
      name: "Application mobile",
      href: "/",
    },
    {
      name: "Boutique en ligne",
      href: "/",
    },
    {
      name: "Maintenance web/mobile",
      href: "/",
    },
  ];
  return (
    <main>
      <Hero />
      <HomeFeatures />
      <HomeServices services={services} />
      <CallToAction />
      <HomeStats />
      <Projects />
      <Testimonials testimonials={testimonials} />
      <HomePartners />
    </main>
  );
};

export default Home;
