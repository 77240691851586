import { createTheme } from "@mui/material";
import { colors } from "./colors";

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary
        },
        secondary: {
            main: colors.secondary
        },
        text: {
            primary: '#3C3C3C'
        }
    },
    typography: {
        fontFamily: 'Open Sans',
        button: {
            textTransform: 'normal'
        },
        a: {
            fontWeight: '500'
        },
        h1: {
            fontWeight: '700',
            letterSpacing: -2
        },
        h2: {
            fontWeight: '700',
            letterSpacing: -2
        },
        h3: {
            fontWeight: '700',
            letterSpacing: -2
        },
        h4: {
            fontWeight: '600',
            letterSpacing: -1
        },
        h5: {
            fontWeight: '600',
            letterSpacing: -1
        },
        h6: {
            fontWeight: '600',
            letterSpacing: -1
        }
    }
})

export default theme