import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const Hero = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: "#111", mt: -1 }}>
      <Container>
        <Grid
          container
          sx={{
            py: 3,
            my: 1,
          }}
          spacing={2}
          direction={"row-reverse"}
        >
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
            sx={{ p: 3 }}
          >
            <Box>
              <Typography color={"white"} component="h1" variant="h3">
                <span style={{ color: theme.palette.primary.main }}>
                  Développez
                </span>{" "}
                votre entreprise
              </Typography>
              <Typography
                color={"white"}
                variant="h5"
                sx={{ my: 1 }}
                fontWeight={"normal"}
                fontSize={30}
              >
                Que vous soyez freelance, entrepreneur ou fondateur de start-up{" "}
                <span
                  style={{ color: theme.palette.primary.main, fontSize: 30 }}
                >
                  .
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign="center">
              <img
                src={"/images/profit-growth.png"}
                alt="Banner intro"
                width="60%"
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
