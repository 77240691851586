import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import Mailjet from 'node-mailjet'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createContact } from '../../redux/actions/mailActions'

const mapState = ({ mail }) => ({
    loading: mail.loading
})

const Newsletter = ({ category }) => {
    const dispatch = useDispatch()
    const { loading } = useSelector(mapState)
    const [contact, setContact] = useState({})



    const handleChange = e => setContact({ ...contact, [e.target.name]: e.target.value })

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(createContact(contact))

    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container sx={{ p: 10, backgroundColor: '#eee' }} spacing={2}>
                <Grid
                    item
                    xs={12} md={6}
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Typography>
                        <img src={'/images/newsletter.png'} alt='Newsletter' width='60%' />
                    </Typography>
                </Grid>
                <Grid container spacing={2} item xs={12} md={6}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant='h4'>Newsletter</Typography>
                            <Typography>Abonnez-vous à la Newsletter et ne manquez plus une seule nouvelle.</Typography>
                        </Box>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="firstName"
                                fullWidth
                                label="Votre prénom"
                                variant='outlined'
                                type="text"
                                required
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="lastName"
                                fullWidth
                                label="Votre nom"
                                variant='outlined'
                                type="text"
                                required
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                required
                                fullWidth
                                label="Entrez votre adresse e-mail"
                                variant='outlined'
                                type="email"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                disabled={loading}
                                fullWidth
                                type='submit'
                                variant='contained'
                                sx={{
                                    textTransform: 'uppercase'
                                }}
                            >
                                S'abonner
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default Newsletter
