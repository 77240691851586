import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const Banner = ({ title, content, image }) => {
  return (
    <Box sx={{ backgroundColor: "#111", mt: -3 }}>
      <Container>
        <Grid container sx={{ p: 3, mb: 8 }} spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Typography color={"primary"} component="h1" variant="h3">
                {title}
              </Typography>
              <Typography component="p" color="white" sx={{ mt: 1 }}>
                {content}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign="center">{image}</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
