import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import BrushIcon from "@mui/icons-material/Brush";
import LiquorIcon from "@mui/icons-material/Liquor";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import PeopleIcon from "@mui/icons-material/People";
import LanguageIcon from "@mui/icons-material/Language";
import DvrIcon from "@mui/icons-material/Dvr";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MenuBookIcon from "@mui/icons-material/MenuBook";

export const brandingValues = [
  {
    title: "Logo & charte graphique",
    content:
      "Obtenez un design de logo qui vous démarquera de vos concurrents.",
    icon: <BrushIcon />,
  },
  {
    title: "Packaging de produit",
    content:
      "La création d'une superbe étiquette qui incite vos clients à dire 'OUI' sur vos produits.",
    icon: <LiquorIcon />,
  },
  {
    title: "Affiches, brochures, etc.",
    content:
      "Un design pour votre affiche éclatant, qui séduit votre audiance!",
    icon: <MenuBookIcon />,
  },
];

export const marketingValues = [
  {
    title: "Marketing de marque",
    content:
      "Une stratégie marketing ciblée sur les propetcs intéressés par votre secteur activité.",
    icon: <ShowChartIcon />,
  },
  {
    title: "Référencement",
    content:
      "Dévenez une référence dans votre domaine, Hashen vous aide à optimiser votre entreprise sur les moteurs de recherche.",
    icon: <LocationSearchingIcon />,
  },
  {
    title: "Community management",
    content:
      "Nos animateurs de communauté vous aident à animer et à fédérer vos réseaux sociaux (facebook, instagram, twitter,...)",
    icon: <PeopleIcon />,
  },
];

export const softValues = [
  {
    title: "Site web",
    content:
      "Des pages web personnalisées qui vous connectent avec vos internautes.",
    icon: <LanguageIcon />,
  },
  {
    title: "Application web",
    content:
      "Des solutions logicielles pour vous aider à mieux pilotez votre activité.",
    icon: <DvrIcon />,
  },
  {
    title: "Application mobile",
    content:
      "Améliorez la productivité de votre équipe grâce à une solution nomade performante.",
    icon: <PhoneIphoneIcon />,
  },
];

export const footerData = {
  entreprise: {
    title: "Entreprise",
    subtitles: [
      {
        title: "Découvrez-nous",
        path: "/a-propos",
      },
      {
        title: "Nos services",
        path: "/nos-services",
      },
      {
        title: "Academy",
        path: "https://academy.hashen.io",
      },
      {
        title: "Mentions légales",
        path: "/mentions",
      },
    ],
  },

  prestations: {
    title: "Prestations",
    subtitles: [
      {
        title: "Image de marque",
        path: "/services/image-de-marque",
      },
      {
        title: "Marketing digital",
        path: "/services/marketing-digital",
      },
      {
        title: "Développement web",
        path: "/services/site-web",
      },
      {
        title: "Développement mobile",
        path: "/services/application-mobile",
      },
    ],
  },
  communnity: {
    title: "Suivez-nous",
    socials: [
      {
        name: "Facebook",
        link: "https://facebook.com/hashen.io",
        icon: <FacebookIcon />,
      },
      {
        name: "Instagram",
        link: "https://instagram.com/hashen.io",
        icon: <InstagramIcon />,
      },
      {
        name: "Linkedin",
        link: "https://linkedin.com/company/hashenweb",
        icon: <LinkedInIcon />,
      },
      {
        name: "Twitter",
        link: "https://twitter.com/hashen_io",
        icon: <TwitterIcon />,
      },
    ],
  },
};

export const howItWork = [
  {
    id: "1",
    title: "Décrivez vos besoins",
    content:
      "Grâce à notre cahier des charges en ligne, vous pouvez très facilement nous décrire votre projet et fixer votre budget.",
  },
  {
    id: "2",
    title: "Suivez l'évolution",
    content:
      "Vous êtes impliqué dans le processus de création afin de vous proposer une solution faite sur mesure.",
  },
  {
    id: "3",
    title: "Trouvez votre satisfaction",
    content:
      "Au terme de la réalisation de votre travail, il ne vous restera qu'à valider votre projet et nous vous cèderons les droits de propriété et nous vous enverrons les fichiers finaux.",
  },
];
