import React from 'react'
import { Box } from '@mui/material'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
    return (
        <main>
            <Header />
            <Box>
                {children}
            </Box>
            <Footer />
        </main>
    )
}

export default Layout
