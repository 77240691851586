import { Button, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const CallToAction = () => {
  const theme = useTheme();
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "space-between",
        p: 5,
        mt: -5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
            color={"white"}
            variant="h5"
          >
            Votre succès digital commence ici
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "right" },
            }}
          >
            <Button
              sx={{
                color: "white",
                borderColor: "white",
                borderRadius: 10,
              }}
              variant="outlined"
            >
              Travaillez avec nous
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CallToAction;
