import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const HomePartners = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoPlay: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partners = [
    {
      name: "Numérique",
      image: "/images/numerique.png",
    },
    {
      name: "Google",
      image: "/images/google.png",
    },
    {
      name: "Meta",
      image: "/images/meta.png",
    },
    {
      name: "entreprenariat",
      image: "/images/entreprenariat.png",
    },
    {
      name: "ANADEC",
      image: "/images/anadec.png",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#111" }}>
      <Container sx={{ py: 5 }}>
        <Typography color={"primary"} textAlign={"center"}>
          Partenaires
        </Typography>
        <Typography
          color={"white"}
          sx={{ my: 2 }}
          variant="h4"
          textAlign={"center"}
        >
          Nous collaborons avec
        </Typography>
        <Slider {...settings}>
          {partners.map((partner) => (
            <Typography>
              <img src={partner.image} width={"100%"} alt="partner" />
            </Typography>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default HomePartners;
