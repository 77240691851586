import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import WhatsApp from "@mui/icons-material/WhatsApp";
import NavItem from "./NavItem";
import { menuList } from "../menuList";
import { Drawer, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SidebarNav = ({ onClose, open, variant }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: 1,
          backgroundColor: "black",
        }}
      >
        <Box>
          <Box width={1} paddingX={2} paddingY={1}>
            <Box
              display={"flex"}
              component="a"
              href="/"
              width={{ xs: 100, md: 120 }}
            >
              <Box
                component={"img"}
                src={"/images/logo-secondary.png"}
                width={"100%"}
              />
            </Box>
          </Box>
          <Box paddingX={2} paddingY={2}>
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ ml: 1, fontSize: 13 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}
            <Button
              component="a"
              href="tel:+243811765187"
              disableElevation
              sx={{
                my: 2,
                borderRadius: 10,
              }}
              startIcon={<WhatsApp />}
              variant="contained"
            >
              Contactez-nous
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
