import Mailjet from 'node-mailjet'

export const createContact = contact => {
    return (dispatch) => {

        dispatch({ type: "CREATE_CONTACT_LOADING", payload: true })


        return Mailjet.apiConnect('09f519c377b139556489ba9cf28912e1', '8b62e45d4b85b6c7214e8af8aa321b02')
            .post("contact", { 'version': 'v3' })
            .request({
                "IsExcludedFromCampaigns": "true",
                "Name": contact.firstName + ' ' + contact.lastName,
                "Email": contact.email
            })
            .then((result) => {
                console.log(result)
                dispatch({ type: "CREATE_CONTACT_SUCCESS" })
            })
            .catch((err) => {
                console.log(err)
                dispatch({ type: "CREATE_CONTACT_ERROR", payload: err.message })
            })
    }
}