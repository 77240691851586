import Layout from './components/Layout';
import { Route, Routes } from 'react-router-dom'
import routes from './routes';

function App() {
  return (
    <Layout>
      <Routes>
        {
          routes.map(route => (
            <Route exact path={route.path} element={route.component} />
          ))
        }
      </Routes>
    </Layout>
  );
}

export default App;
