import About from "./views/about";
import ContactUs from "./views/about/ContactUs";
import Mentions from "./views/about/Mentions";
import Branding from "./views/branding";
import Home from "./views/home";
import Marketing from "./views/marketing";
import MobileApps from "./views/mobileapp";
import Soft from "./views/soft";

const routes = [
  {
    name: "home",
    path: "/",
    component: <Home />,
  },
  {
    name: "about",
    path: "/a-propos",
    component: <About />,
  },
  {
    name: "contact",
    path: "/nous-contacter",
    component: <ContactUs />,
  },
  {
    name: "branding",
    path: "/services/image-de-marque",
    component: <Branding />,
  },
  {
    name: "marketing",
    path: "/services/marketing-digital",
    component: <Marketing />,
  },
  {
    name: "MobileApps",
    path: "/services/application-mobile",
    component: <MobileApps />,
  },
  {
    name: "web",
    path: "/services/site-web",
    component: <Soft />,
  },
  {
    name: "mentions",
    path: "/mentions",
    component: <Mentions />,
  },
];

export default routes;
