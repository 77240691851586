import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

const HomeStats = () => {
  const stats = [
    {
      name: "Projets réalisés",
      number: 200,
      icon: "",
    },
    {
      name: "Clients satisfaits",
      number: 50,
      icon: "",
    },
    {
      name: "Sites/Applications web",
      number: 30,
      icon: "",
    },
    {
      name: "Applications mobiles",
      number: 20,
      icon: "",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "black", mt: -1, color: "white" }}>
      <Container>
        <Grid sx={{ py: 5, pb: 10 }} container spacing={2}>
          {stats.map((stat) => (
            <Grid item xs={6} md={3}>
              <Typography variant="h4" textAlign={"center"}>
                <CountUp
                  separator=""
                  prefix="+"
                  end={stat.number}
                  duration={30}
                />
              </Typography>
              <Typography color={"primary"} textAlign={"center"}>
                {stat.name}{" "}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeStats;
